/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import React from "react";
import { on } from "events";

interface Props {
  name: string;
  description: string;
  value: string;

  onSaveAndRunClick: (onlySave: boolean) => void;
  onCancel: () => void;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeShowSecret?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  showSecret?: boolean;
}

export default function CreateEditSecretForm({
  name,
  description,
  value,
  onSaveAndRunClick,
  onCancel,
  onChangeName,
  onChangeValue,
  onChangeDescription,
  onChangeShowSecret,
  showSecret,
}: Props) {
  return (
    <>
      <div className="flex justify-between items-center">
        <nav className="flex my-4 text-lg" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/secrets"
                  className="text-gray-400 hover:text-gray-200"
                >
                  Secrets
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div className="ml-4 text-md text-gray-400" aria-current={true}>
                  {name}
                </div>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="w-full border-t border-gray-400 py-1"></div>
      <form>
        <div className="space-y-6">
          <div className="border-b border-white/10 pb-12">
            <div className="">
              <div className="mt-4">
                <label
                  htmlFor="secretName"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Secret name
                </label>
                <div className="mt-2">
                  <div className="flex w-[400px] rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      onChange={onChangeName}
                      value={name}
                      type="text"
                      name="secretName"
                      id="secretName"
                      className="flex-1 w-[400px] border-0 bg-transparent py-1.5 pl-3 text-white focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 flex flex-row flex-wrap w-full">
                <div>
                  <label
                    htmlFor="secretValue"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Secret Value
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <input
                        onChange={onChangeValue}
                        disabled={!showSecret}
                        value={value}
                        type="text"
                        name="secretValue"
                        id="secretValue"
                        className="flex-1 w-[400px] border-0 bg-transparent py-1.5 pl-3 text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                {onChangeShowSecret && (
                  <div className="flex items-end ml-2 mt-2">
                    <button
                      onClick={onChangeShowSecret}
                      type="button"
                      className="w-28 h-9 rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-400 focus:bh-pink-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      {!showSecret ? "Show" : "Hide"} Secret
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Description <span className="text-gray-400">(Optional)</span>
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={onChangeDescription}
                    value={description}
                    id="description"
                    name="description"
                    rows={2}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 flex items-center justify-end gap-x-6">
          <button
            onClick={onCancel}
            type="button"
            className="text-sm font-semibold leading-6 text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => onSaveAndRunClick(true)}
            type="button"
            className="w-28 h-9 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}
