import { Client } from "@hey-api/client-fetch";
import {
  GetSecretByIdResponse,
  getSecretBySecretId,
  getSecretBySecretIdDecrypt,
} from "./api-client";
import { getGlobalClient } from "./api-client-config";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { saveAndRunClick } from "./create-edit-secret-helper";
import React from "react";
import CreateEditSecretForm from "./create-edit-secret";

const DEFAULT_SECRET_VALUE = "********";

interface GetRunnerByRunnerIdFetcherInput {
  client: Client;
  secretId: string;
}

const getSecretBySecretIdFetcher = async ({
  client,
  secretId,
}: GetRunnerByRunnerIdFetcherInput): Promise<GetSecretByIdResponse> => {
  // if (!runnerId) {
  //   return {};
  // }
  const response = await getSecretBySecretId({ client, path: { secretId } });
  if (response.error) {
    console.error(response.error);
    throw new Error(response.error.message);
  } else {
    return response.data;
  }
};

interface Props {}

export default function EditSecret(props: Props) {
  const client = getGlobalClient();
  const navigate = useNavigate();

  const [secretName, setSecretName] = useState("");
  const [description, setDescription] = useState("");
  const [secretValue, setSecretValue] = useState(DEFAULT_SECRET_VALUE);
  const [showSecret, setShowSecret] = useState(false);

  const { secretId } = useParams();
  const { data, error, isLoading, mutate } = useSWR(
    `/secrets/${secretId}`,
    async () =>
      getSecretBySecretIdFetcher({ client, secretId: secretId || "" }),
  );

  console.log("data", data);

  useEffect(() => {
    async function handleShowSecretClick() {
      if (showSecret) {
        const response = await getSecretBySecretIdDecrypt({
          client,
          path: { secretId: secretId || "" },
        });
        if (response.error || !response.data) {
          console.error(response.error);
        } else {
          console.log("response", response);
          setSecretValue(response.data.secretValue);
        }
      } else {
        setSecretValue(DEFAULT_SECRET_VALUE);
      }
    }
    handleShowSecretClick().catch(console.error);
  }, [showSecret]);

  async function handleSaveAndRunClick(
    data: GetSecretByIdResponse | undefined,
  ) {
    await saveAndRunClick({
      client,
      secretId,
      secretName: data?.secretName || "",
      description: data?.description || "",
      secretValue: showSecret ? secretValue : undefined,
    });
    navigate("/secrets");
  }

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <CreateEditSecretForm
      name={data.secretName}
      description={data.description || ""}
      value={secretValue}
      onSaveAndRunClick={() => handleSaveAndRunClick(data)}
      onCancel={() => navigate("/secrets")}
      onChangeName={(event) => {
        mutate(
          { ...data, secretName: event.target.value ?? "" },
          { revalidate: false },
        );
      }}
      onChangeValue={(e) => setSecretValue(e.target.value)}
      onChangeDescription={(event) => {
        mutate(
          { ...data, description: event.target.value },
          { revalidate: false },
        );
      }}
      onChangeShowSecret={() => {
        setShowSecret(!showSecret);
      }}
      showSecret={showSecret}
    />
  );
}
