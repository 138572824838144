import "./App.css";
import { Amplify } from "aws-amplify";
import {
  autoSignIn,
  signInWithRedirect,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useCallback, useEffect } from "react";

import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React from "react";
import { setGlobalClient } from "./api-client-config";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes2";
// import { setGlobalWSClient } from "./ws-notifications-client";

// async function handleCurrentUserInfo() {
//   try {
//     const signInWithRedirectOutput = await currentUserInfo();
//     // handle sign-in steps
//   } catch (error) {
//     console.log(error);
//   }
// }

async function handleFetchUserAttributes() {
  try {
    // const userAttributes = await fetchUserAttributes();
    // console.log('userAttributes: ' + userAttributes);
    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();
    console.log("accessToken: " + accessToken);
    setGlobalClient({
      baseUrl: process.env.REACT_APP_HTTP_API_URL || "",
      token: accessToken || "",
    });
    // setGlobalWSClient({
    //   baseUrl: "wss://z4isw3tg1m.execute-api.us-east-1.amazonaws.com/prod/",
    //   token: accessToken || "",
    // })
  } catch (error) {
    console.log(error);
  }
}

async function handleAutoSignIn() {
  try {
    const signInWithRedirectOutput = await autoSignIn();
    // handle sign-in steps
  } catch (error) {
    console.log(error);
  }
}

// async function handleSignIn() {
//   try {
//     const signInWithRedirectOutput = await signInWithRedirect();
//     // handle sign-in steps
//   } catch (error) {
//     console.log(error);
//   }
// }

async function currentAuthenticatedUser() {
  try {
    const user = getCurrentUser();
    console.log("USER: " + JSON.stringify(user, null, 2));
  } catch (e) {
    console.error(e);
  }
}

async function currentSession() {
  try {
    const session = (await fetchAuthSession()) ?? {};
    const jwt = session.tokens?.accessToken?.toString();
    console.log("jwt: " + jwt);
    // console.log("SESSION: " + JSON.stringify(session, null, 2));
  } catch (err) {
    console.log(err);
  }
}

// @ts-ignore
Hub.listen("auth", ({ payload: { event, data } }) => {
  switch (event) {
    case "signedIn":
      console.log("user signed in");
      currentSession();
      break;
    default:
      console.log("event: " + event);
      console.log("data: ", +JSON.stringify(data));
  }
});

const userPoolId = process.env.REACT_APP_USER_POOL_ID || "";
const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID || "";
const userPoolDomain = process.env.REACT_APP_USER_POOL_DOMAIN || "";
console.log("userPoolId: " + userPoolId);
console.log("userPoolClientId: " + userPoolClientId);
console.log("userPoolDomain: " + userPoolDomain);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId,
      userPoolId,
      loginWith: {
        oauth: {
          domain: userPoolDomain,
          scopes: ["email", "openid", "phone"],
          redirectSignIn: ["http://localhost:3000"],
          redirectSignOut: ["http://localhost:3000"],
          // @ts-ignore
          // redirectSignIn: [process.env.REACT_APP_MAIN_ORIGIN],
          // @ts-ignore
          // redirectSignOut: [process.env.REACT_APP_MAIN_ORIGIN],
          responseType: "token",
          // providers?: Array<OAuthProvider | CustomProvider>;
        },
      },
      // userPoolEndpoint?: string | undefined,
      // signUpVerificationMethod: "code" | "link" | undefined,
      // loginWith?: {
      //     oauth?: OAuthConfig | undefined;
      //     username?: boolean | undefined;
      //     email?: boolean | undefined;
      //     phone?: boolean | undefined;
      // } | undefined;
      // userAttributes?: Partial<. ..> | undefined;
      // mfa?: {
      //     ...;
      // } | undefined;
      // passwordFormat?: {
      //     ...;
      // } | undefined;
    },
    // // @ts-ignore
    // region: 'us-east-1',
    // userPoolId: 'us-east-1_n2whFIw8O',
    // userPoolWebClientId: '4pins8q12n3rev4b4mt2sdkfbf',
    // mandatorySignIn: true,
    // oauth: {
    //   domain: 'https://ian-test-domain.auth.us-east-1.amazoncognito.com',
    //   scope: ['email', 'openid', 'profile'],
    //   redirectSignIn: 'http://localhost:3000',
    //   redirectSignOut: 'http://localhost:3000',
    //   responseType: 'code',
    // },
  },
});

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, signOut, user }: Props) {
  const [isLoading, setIsLoading] = React.useState(true);
  useCallback(async (props: any) => {
    const response = await handleAutoSignIn();

    await currentAuthenticatedUser();
    await currentSession();
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      await handleFetchUserAttributes();
      setIsLoading(false);
    };

    fetchCurrentUser()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  // useEffect(() => {
  //   const fetchSession = async () => {
  //     await currentSession();
  //   };

  //   fetchSession()
  //     // make sure to catch any error
  //     .catch(console.error);;
  // }, [])
  return <RouterProvider router={router} />;
  // return <Shell loading={isLoading} />;
}

export default withAuthenticator(App);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
