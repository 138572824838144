import React from "react";
import { ChangeEventHandler, useState } from "react";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
// const people = [
//   { id: 1, name: 'Annette Black' },
//   { id: 2, name: 'Cody Fisher' },
//   { id: 3, name: 'Courtney Henry' },
//   { id: 4, name: 'Kathryn Murphy' },
//   { id: 5, name: 'Theresa Webb' },
// ]

interface CheckboxInput {
  datum: { id: string; label: string };
  onCheck: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  disabled?: boolean;
}

export default function Checkbox(props: CheckboxInput) {
  const [checked, setChecked] = useState(false);

  const handleOnCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(e.target.checked);
    props.onCheck(e);
  };

  const labelTextCss = checked ? "text-white" : "text-gray-400";

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={`${props.datum.id}`}
          aria-describedby="days-of-week-checkboxes"
          value={`${props.datum.id}`}
          type="checkbox"
          onChange={handleOnCheck}
          disabled={props.disabled}
          className="h-4 w-4 rounded bg-gray-600 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label
          htmlFor={`${props.datum.id}`}
          className={`font-medium ${labelTextCss}`}
        >
          {`${props.datum.label}`}
        </label>{" "}
        <span id="comments-description" className="text-gray-500">
          <span className="sr-only">{`${props.datum.label}`}</span>
        </span>
      </div>
    </div>
  );
}
