import React, { FunctionComponent, useState } from "react";
import Minutes from "./minutes-select";
import Hour from "./hour-select";

interface DailyCronProp {
  onChange(e?: string[]): void;
  value: string[];
  translate(e: string): string;
  disabled?: boolean;
}
interface State {
  hour: number;
  minute: number;
}
const DailyCron: FunctionComponent<DailyCronProp> = (props) => {
  const [state, setState] = useState<State>({
    hour: 0,
    minute: 0,
  });

  // useEffect(() => {
  //   setState({ ...state, every: props.value[3] !== "?" });
  // }, []);

  // const onDayChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
  //   const value = parseInt(e.target.value);
  //   if (!value || (value > 0 && parseInt(e.target.value) < 32)) {
  //     // props.value = ['0', getValueByIndex(1), getValueByIndex(1),'*','*','?','*'];
  //     onValueChange(3, e.target.value ? `1/${e.target.value}` : e.target.value);
  //   }
  // };

  const onAtHourChange = (e: { target: { value: string } }) => {
    onValueChange(1, e.target.value);
  };

  const onAtMinuteChange = (e: { target: { value: string } }) => {
    onValueChange(0, e.target.value);
  };

  const onValueChange = (cronPosition: number, value: string) => {
    console.log("onValueChange", cronPosition, value, props.value);
    let val = props.value;
    val[cronPosition] = value;
    props.onChange(val);
  };

  const translateFn = props.translate;
  return (
    <div>
      <div className="flex items-center">
        <span className="text-white mr-2">{translateFn("Every day at")}</span>
        <Hour
          onChange={onAtHourChange}
          value={props.value[1]}
          className={`mr-2`}
          disabled={props.disabled}
        />
        <Minutes
          onChange={onAtMinuteChange}
          value={props.value[0]}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
export default DailyCron;
