import { ChangeEventHandler, useState } from "react";
import { Switch } from "@headlessui/react";
import React from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface ToggleInput {
  initialEnabled?: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

export default function Toggle(props: ToggleInput) {
  const [enabled, setEnabled] = useState(props.initialEnabled || false);

  const handleToggleChange = (checked: boolean) => {
    props.onChange(checked);
    setEnabled(checked);
  };

  return (
    <Switch
      checked={enabled}
      onChange={handleToggleChange}
      className={classNames(
        enabled ? "bg-indigo-600" : "bg-gray-600",
        `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${props.className}`,
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      />
    </Switch>
  );
}
