import React from "react";
import { ChangeEventHandler, ReactNode } from "react";

export interface SelectInput {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  value: string;
  options: ReactNode[];
  className?: string;
  disabled?: boolean;
}

export default function Select({
  onChange,
  value,
  options,
  className = "",
  disabled = false,
}: SelectInput) {
  return (
    <select
      disabled={disabled}
      className={`w-18 rounded-md border-0 text-white bg-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black ${className}`}
      onChange={onChange}
      value={value}
    >
      {options}
    </select>
  );
}
