import { Client, createClient } from "@hey-api/client-fetch";

let globalClient: Client | undefined;
let jwt: string | undefined;

export function getGlobalClient() {
  if (!globalClient) {
    throw new Error(
      "Global client not set. Call setGlobalClient() before calling getGlobalClient().",
    );
  }
  return globalClient;
}

export function getJwt() {
  if (!jwt) {
    throw new Error(
      "JWT not set. Call setGlobalClient() before calling getJwt().",
    );
  }
  return jwt;
}

interface SetGlobalClientInput {
  baseUrl: string;
  token: string;
}

export function setGlobalClient({ baseUrl, token }: SetGlobalClientInput) {
  jwt = token;
  globalClient = createClient({
    // set default base url for requests made by this client
    baseUrl,
    global: true,
    /**
     * Set default headers only for requests made by this client. This is to
     * demonstrate global clients and their configuration.
     */
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
  });
  globalClient.interceptors.request.use((request, options) => {
    request.headers.set("Authorization", `Bearer ${token}`);
    return request;
  });
}
