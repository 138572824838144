import Minutes, { MinutesCronProp } from "./minutes";
import Daily from "./daily";
import Hourly from "./hourly";
import Weekly from "./weekly";
import Monthly from "./monthly";
import { FunctionComponent } from "react";

export type HeaderKeyType =
  | "MINUTES"
  | "HOURLY"
  | "DAILY"
  | "WEEKLY"
  | "MONTHLY";
export type HeaderValType =
  | "Minutes"
  | "Hourly"
  | "Daily"
  | "Weekly"
  | "Monthly";
interface HeadersKeyInterface {
  MINUTES: "MINUTES";
  HOURLY: "HOURLY";
  DAILY: "DAILY";
  WEEKLY: "WEEKLY";
  MONTHLY: "MONTHLY";
}
interface HeadersInterface {
  MINUTES: "Minutes";
  HOURLY: "Hourly";
  DAILY: "Daily";
  WEEKLY: "Weekly";
  MONTHLY: "Monthly";
}
export const HEADER: HeadersKeyInterface = {
  MINUTES: "MINUTES",
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

export const HEADER_VALUES: HeadersInterface = {
  MINUTES: "Minutes",
  HOURLY: "Hourly",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

const defaultTabs: HeaderValType[] = [
  HEADER_VALUES.MINUTES,
  HEADER_VALUES.HOURLY,
  HEADER_VALUES.DAILY,
  HEADER_VALUES.WEEKLY,
  HEADER_VALUES.MONTHLY,
];

interface MetadataInterface {
  component: FunctionComponent<MinutesCronProp>;
  name: HeaderValType;
  initialCron: string[];
}

export const metadata: MetadataInterface[] = [
  {
    component: Minutes,
    name: HEADER_VALUES.MINUTES,
    initialCron: ["*/10", "*", "*", "*", "?"],
  },
  {
    component: Hourly,
    name: HEADER_VALUES.HOURLY,
    initialCron: ["0", "*/1", "*", "*", "?"],
  },
  {
    component: Daily,
    name: HEADER_VALUES.DAILY,
    initialCron: ["0", "0", "*", "*", "?"],
  },
  {
    component: Weekly,
    name: HEADER_VALUES.WEEKLY,
    initialCron: ["0", "0", "*", "*", ",,,,,,"],
  },
  {
    component: Monthly,
    name: HEADER_VALUES.MONTHLY,
    initialCron: ["0", "0", "0", "*", "?"],
  },
];

const validateHeaders = (headers: HeaderKeyType[]): HeaderValType[] => {
  const validatedHeaders: HeaderValType[] = [];
  headers.forEach((header) => {
    if (!HEADER_VALUES[header]) {
      throw new Error("Invalid header " + header);
      // Avoid duplicates
    } else if (validatedHeaders.indexOf(HEADER_VALUES[header]) === -1) {
      validatedHeaders.push(HEADER_VALUES[header]);
    }
  });
  return validatedHeaders;
};

/**
 * Validate and load headers
 * @param {*} options
 */
export const loadHeaders = (options?: {
  headers: HeaderKeyType[];
}): HeaderValType[] => {
  if (options) {
    if (options.headers) {
      if (!options.headers.length) {
        throw new Error("Atleast one header is required.");
      }
      return validateHeaders(options.headers);
    }
  }
  return defaultTabs;
};
