import { useState } from "react";
import { getGlobalClient } from "./api-client-config";
import CreateEditSecretForm from "./create-edit-secret";
import React from "react";
import { useNavigate } from "react-router-dom";
import { saveAndRunClick } from "./create-edit-secret-helper";

interface Props {}

export default function CreateSecret(props: Props) {
  const client = getGlobalClient();
  const navigate = useNavigate();

  const [secretName, setSecretName] = useState("");
  const [description, setDescription] = useState("");
  const [secretValue, setSecretValue] = useState("");

  async function handleSaveAndRunClick() {
    await saveAndRunClick({
      client,
      secretName,
      description,
      secretValue,
    });
    navigate("/secrets");
  }

  return (
    <CreateEditSecretForm
      name={secretName}
      description={description}
      value={secretValue}
      onSaveAndRunClick={handleSaveAndRunClick}
      onCancel={() => navigate("/secrets")}
      onChangeName={(e) => setSecretName(e.target.value)}
      onChangeValue={(e) => setSecretValue(e.target.value)}
      onChangeDescription={(e) => setDescription(e.target.value)}
      showSecret={true}
    />
  );
}
