import React from "react";

interface ButtonInput {
  onClick: () => void;
  text: string;
}

export default function Button({ onClick, text }: ButtonInput) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="h-8 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
    >
      {text}
    </button>
  );
}
