/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HEADER_VALUES, HeaderValType } from "./components/cron-scheduler/meta";
import useSWR from "swr";
import { getRunnerByRunnerId } from "./api-client/services.gen";
import React from "react";
import { GetRunnerResponse, ListSecretsResponse } from "./api-client";
import { getGlobalClient } from "./api-client-config";
import { Client } from "@hey-api/client-fetch";
import { getSecretFetcher, saveAndRunClick } from "./create-edit-runner-helper";
import CreateEditRunnerForm from "./create-edit-runner-form";
import { DefaultCron } from "./components/cron-scheduler/cron-scheduler";

interface HandleSaveAndRunClickInput {
  runnerName: string;
  description: string;
  scheduleEnabled: boolean;
}

interface GetRunnerByRunnerIdFetcherInput {
  client: Client;
  runnerId: string;
}

const getRunnerByRunnerIdFetcher = async ({
  client,
  runnerId,
}: GetRunnerByRunnerIdFetcherInput): Promise<GetRunnerResponse> => {
  // if (!runnerId) {
  //   return {};
  // }
  const response = await getRunnerByRunnerId({ client, path: { runnerId } });
  if (response.error) {
    console.error(response.error);
    throw new Error(response.error.message);
  } else {
    return response.data;
  }
};

interface Props {
  isEdit?: boolean;
}

export default function CreateEditRunner(props: Props) {
  const client = getGlobalClient();
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [cron, setCron] = useState<{
    scheduleMode: HeaderValType;
    cron: string[];
    humanReadableCron: string;
  }>({
    scheduleMode: HEADER_VALUES.MINUTES,
    cron: DefaultCron.split(" "),
    humanReadableCron: "",
  });
  const [secretStates, setSecretStates] = useState<
    { secretId: string; secretName: string; isSelected: boolean }[]
  >([]);
  const [collectionFile, setCollectionFile] = useState<File | null>(null);
  const [environmentFile, setEnvironmentFile] = useState<File | null>(null);
  // Preferences should be stored in localstorage
  const navigate = useNavigate();

  const { runnerId } = useParams();
  const { data, error, isLoading, mutate } = useSWR(
    `/runners/${runnerId}`,
    async () =>
      getRunnerByRunnerIdFetcher({ client, runnerId: runnerId || "" }),
    {
      onSuccess: (runnerData) => {
        // console.log("data", runnerData);
        const secretsWithSelected = secretStates.map((secret) => {
          // console.log("secret", secret);
          return {
            ...secret,
            isSelected: (runnerData?.secretIds || []).includes(secret.secretId),
          };
        });
        setSecretStates(secretsWithSelected);
      },
    },
  );

  function handleSecretSuccess(secrets: ListSecretsResponse["secrets"]) {
    const secretsWithSelected = (secrets || []).map((secret) => {
      // console.log("secret", secret);
      // console.log("data?.secretIds?", data?.secretIds);
      return {
        ...secret,
        isSelected: (data?.secretIds || []).includes(secret.secretId),
      };
    });
    setSecretStates(secretsWithSelected);
  }

  const { error: secretError, isLoading: secretLoading } = useSWR(
    "secrets",
    () => getSecretFetcher(client),
    {
      onSuccess: handleSecretSuccess,
    },
  );

  // console.log("DATA: " + JSON.stringify(data, null, 2));
  // console.log("SECRETS: " + JSON.stringify(secrets));

  if (secretError) {
    console.log("secretError", secretError);
  }

  // const secretsWithSelected = (secrets || []).map((secret) => {
  //   return {
  //     ...secret,
  //     isSelected: data?.secretIds?.includes(secret.secretId) || false,
  //   };
  // });
  // console.log("secretsWithSelected", JSON.stringify(secretsWithSelected));

  async function handleSaveAndRunClick(data: GetRunnerResponse) {
    console.log("collectionFile", collectionFile);
    await saveAndRunClick({
      client,
      runnerId,
      collectionFile: collectionFile!,
      collectionName: collectionFile
        ? collectionFile!.name
        : data?.collectionName || "",
      collectionId: data?.collectionId,
      environmentFile: environmentFile!,
      runnerName: data.name,
      description: data.description,
      cron: {
        scheduleMode: data.scheduleMode as HeaderValType,
        cron: data.cron.split(" "),
      },
      scheduleEnabled: data.scheduleEnabled,
      secretIds: secretStates
        .filter((s) => s.isSelected)
        .map((s) => s.secretId),
    });
    navigate("/runners");
  }

  // const disabledScheduleCss = !scheduleEnabled
  //   ? "absolute top-0 left-0 h-48 w-full opacity-30 bg-gray-700 z-auto pointer-events-none"
  //   : "";

  if (isLoading || !data || secretLoading) {
    return <div>Loading...</div>;
  }

  return (
    <CreateEditRunnerForm
      name={data.name}
      onChangeName={(event) => {
        mutate(
          { ...data, name: event.target.value ?? "" },
          { revalidate: false },
        );
      }}
      description={data.description}
      secrets={secretStates}
      handleOnSecretChange={(secretId) => {
        console.log("secretId", secretId);
        const newSecrets = secretStates.map((s) => {
          if (s.secretId === secretId) {
            console.log("s", s);
            return { ...s, isSelected: !s.isSelected };
          }
          return s;
        });
        console.log("newSecrets", newSecrets);
        setSecretStates(newSecrets);
      }}
      onChangeDescription={(event) => {
        mutate(
          { ...data, description: event.target.value },
          { revalidate: false },
        );
      }}
      onCancel={() => navigate("/runners")}
      onSaveAndRunClick={() => handleSaveAndRunClick(data as GetRunnerResponse)}
      onCollectionFileSelect={(file: File) => setCollectionFile(file)}
      onEnvironmentFileSelect={(file: File) => setEnvironmentFile(file)}
      selectedCollectionFile={data.collectionName}
      selectedEnvironmentFile={data.environmentName}
      handleSchedulerChange={(scheduleMode, val, humanReadableCron) => {
        mutate(
          { ...data, cron: val.join(" "), scheduleMode },
          { revalidate: false },
        );
      }}
      handleScheduleToggle={(enabled) => {
        mutate({ ...data, scheduleEnabled: enabled }, { revalidate: false });
      }}
      scheduleEnabled={data.scheduleEnabled}
      cron={
        data.cron
          ? {
              cron: data.cron.split(" "),
              scheduleMode: data.scheduleMode || HEADER_VALUES.DAILY,
            }
          : {
              cron: [],
              scheduleMode: HEADER_VALUES.DAILY,
            }
      }
    />
  );
}
