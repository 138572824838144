import { createBrowserRouter } from "react-router-dom";

import Runners from "./runners";
import ErrorPage from "./error-page";
import React from "react";
import Shell from "./shell";
import { getGlobalClient } from "./api-client-config";
import { sleep } from "./utils";
import CreateRunner from "./create-runner";
import EditRunner from "./edit-runner";
import Secrets from "./secrets";
import CreateSecret from "./create-secret";
import EditSecret from "./edit-secret";
import Runs from "./runs";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Shell loading={false} />,
    errorElement: <ErrorPage />,
    loader: async () => {
      let retryCount = 200;
      while (retryCount > 0) {
        retryCount--;
        let client;
        try {
          client = getGlobalClient();
        } catch (e) {
          console.log("Error getting client. Retrying...");
        }
        if (client) {
          console.log("Found client");
          return client;
        }
        await sleep(10);
      }
      if (retryCount === 0) {
        console.log("Failed to get client");
      }
      return null;
    },
    // loader: rootLoader,
    children: [
      {
        path: "runners",
        element: <Runners />,
        // loader: teamLoader,
      },
      {
        path: "runners/:runnerId",
        element: <EditRunner />,
      },
      {
        path: "runners/new",
        element: <CreateRunner />,
      },
      {
        path: "runners/:runnerId/runs",
        element: <Runs />,
      },
      {
        path: "secrets",
        element: <Secrets />,
      },
      {
        path: "secrets/new",
        element: <CreateSecret />,
      },
      {
        path: "secrets/:secretId",
        element: <EditSecret />,
      },
    ],
  },
]);
// createRoutesFromElements(
//   <Routes>
//     <Route
//       path="/"
//       element={
//         <App isPassedToWithAuthenticator={false} />
//       }
//       errorElement={<ErrorPage />}
//     >
//       <Route path="runners" element={<Runners />} />
//       <Route path="runners/:runnerId" element={<CreateEditRunner />} />
//       <Route path="runners/new" element={<CreateEditRunner />} />
//     </Route>
//   </Routes>
// )
// );
