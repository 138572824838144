import React, { ChangeEventHandler, FunctionComponent } from "react";
import Minutes from "./minutes-select";
import Hour from "./hour-select";
import Checkbox from "../checkbox";

const expectedDaysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

interface WeeklyCronProp {
  onChange(e?: string[]): void;
  value: string[];
  translate(e: string): string;
  disabled?: boolean;
}

const WeeklyCron: FunctionComponent<WeeklyCronProp> = (props) => {
  const onAtHourChange = (e: { target: { value: string } }) => {
    let val = props.value;
    val[1] = `${e.target.value}`;
    props.onChange(val);
  };

  const onAtMinuteChange = (e: { target: { value: string } }) => {
    let val = props.value;
    val[0] = `${e.target.value}`;
    props.onChange(val);
  };

  const dayIdToIndex: { [key: string]: string } = {
    MON: "1",
    TUE: "2",
    WED: "3",
    THU: "4",
    FRI: "5",
    SAT: "6",
    SUN: "0",
  };

  const onCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    console.log("val: " + props.value);

    const val = props.value;
    const indexOfDoW = expectedDaysOfWeek.indexOf(e.target.id);
    if (indexOfDoW === -1) {
      console.error("Invalid day of week:" + e.target.id);
      return;
    }
    const isAddingCheck = e.target.checked;
    const newDaysOfWeek = val[4].split(",");
    newDaysOfWeek[indexOfDoW] = isAddingCheck ? dayIdToIndex[e.target.id] : "";
    const newDaysOfWeekJoined = newDaysOfWeek
      .reduce((acc: string[], dow) => {
        if (dow !== "") {
          acc.push(dow);
        }
        return acc;
      }, [])
      .join(",");
    // val[4] = newDaysOfWeekJoined.startsWith(",")
    //   ? newDaysOfWeekJoined
    //   : newDaysOfWeekJoined.substring(1);
    val[4] = newDaysOfWeekJoined === "" ? "?" : newDaysOfWeek.join(",");

    // if (e.target.checked) {
    //   onDayChecked(val, e);
    // } else {
    //   onDayUnChecked(val, e);
    // }
    console.log("new val: " + val);
    props.onChange(val);
  };

  const translateFn = props.translate;

  console.log("cron value: " + props.value);

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="h-32 flex flex-col justify-start flex-wrap gap-2">
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.MON) !== -1 ? true : false
              }
              datum={{ id: "MON", label: translateFn("Monday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.TUE) !== -1 ? true : false
              }
              datum={{ id: "TUE", label: translateFn("Tuesday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.WED) !== -1 ? true : false
              }
              datum={{ id: "WED", label: translateFn("Wednesday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.THU) !== -1 ? true : false
              }
              datum={{ id: "THU", label: translateFn("Thursday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.FRI) !== -1 ? true : false
              }
              datum={{ id: "FRI", label: translateFn("Friday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.SAT) !== -1 ? true : false
              }
              datum={{ id: "SAT", label: translateFn("Saturday") }}
              disabled={props.disabled}
            />
            <Checkbox
              onCheck={onCheck}
              checked={
                props.value[4].search(dayIdToIndex.SUN) !== -1 ? true : false
              }
              datum={{ id: "SUN", label: translateFn("Sunday") }}
              disabled={props.disabled}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <span className="mr-2 text-gray-400">{translateFn("at")}</span>
        <Hour
          onChange={onAtHourChange}
          value={props.value[1]}
          className="mr-2"
          disabled={props.disabled}
        />
        <Minutes
          onChange={onAtMinuteChange}
          value={props.value[0]}
          className="mr-2"
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default WeeklyCron;
