import React, { FunctionComponent, useEffect, useState } from "react";
import Minutes from "./minutes-select";
import Hour from "./hour-select";
import Select from "../select";

interface MonthlyCronProp {
  onChange(e?: string[]): void;
  value: string[];
  translate(e: string): string;
  disabled?: boolean;
}
interface State {
  hour: number;
  minute: number;
}

const buildOptions = () => {
  let options = [];
  for (let i = 0; i < 29; i++) {
    options.push(
      <option key={i} id={i.toString()}>
        {(i < 10 ? "0" : "") + i}
      </option>,
    );
  }
  return options;
};
const options = buildOptions();

const MonthlyCron: FunctionComponent<MonthlyCronProp> = (props) => {
  const [state, setState] = useState<State>({ hour: 0, minute: 0 });

  // useEffect(() => {
  //   let every;
  //   if (props.value[3] === "L") {
  //     every = "2";
  //   } else if (props.value[3] === "LW") {
  //     every = "3";
  //   } else if (props.value[3].startsWith("L")) {
  //     every = "4";
  //   } else {
  //     every = "1";
  //   }
  //   setState({ ...state });
  // }, []);
  const onDayChange = (e: { target: { value: string } }) => {
    if (
      (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 31) ||
      e.target.value === ""
    ) {
      const val = [
        props.value[0],
        props.value[1],
        e.target.value,
        props.value[3],
        props.value[4],
      ];
      props.onChange(val);
    }
  };

  const onAtHourChange = (e: { target: { value: string } }) => {
    const val = [
      props.value[0],
      e.target.value,
      props.value[2],
      props.value[3],
      props.value[4],
    ];
    props.onChange(val);
  };

  const onAtMinuteChange = (e: { target: { value: string } }) => {
    const val = [
      e.target.value,
      props.value[1],
      props.value[2],
      props.value[3],
      props.value[4],
    ];
    props.onChange(val);
  };

  const translateFn = props.translate;

  return (
    <div className="tab-pane">
      <div className="mb-4 flex items-center">
        <div className="mr-2 text-gray-400 w-7 text-right">
          {translateFn("Day")}
        </div>
        <Select
          onChange={onDayChange}
          value={props.value[2]}
          options={options}
          className="mr-2"
        />
        <span className="mr-2 text-gray-400">
          {translateFn("of every month")}
        </span>
      </div>

      <div className="flex items-center">
        <div className="mr-2 text-gray-400 w-7 text-right">
          {translateFn("at")}
        </div>
        <Hour
          onChange={onAtHourChange}
          value={props.value[1]}
          className="mr-2"
        />
        <Minutes
          onChange={onAtMinuteChange}
          value={props.value[0]}
          className="mr-2"
        />
      </div>
    </div>
  );
};

export default MonthlyCron;
