/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import FileUpload from "./components/file-upload";
import CronScheduler from "./components/cron-scheduler/cron-scheduler";
import { HeaderValType } from "./components/cron-scheduler/meta";
import Toggle from "./components/toggle";
import React, { ChangeEventHandler } from "react";

function SecretCheckbox({
  secretId,
  secretName,
  isSelected,
  onSecretChange,
}: {
  secretId: string;
  secretName: string;
  isSelected: boolean;
  onSecretChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <>
      <div key={secretId} className="flex flex-wrap h-6 items-center mb-1">
        <input
          id={secretId}
          name={secretName}
          checked={isSelected}
          onChange={onSecretChange}
          type="checkbox"
          className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
        />
        <div className="text-sm ml-2">
          <label
            htmlFor={secretId}
            className="font-medium text-white w-fit whitespace-nowrap"
          >
            {secretName}
          </label>
        </div>
      </div>
    </>
  );
}

interface Props {
  name: string;
  description: string;
  secrets: { secretId: string; secretName: string; isSelected: boolean }[];
  handleOnSecretChange: (secretId: string) => void;

  scheduleEnabled: boolean;
  cron: {
    cron: string[];
    scheduleMode: HeaderValType;
  };
  handleSchedulerChange: (
    scheduleMode: HeaderValType,
    newCron: string[],
    humanReadableCron: string,
  ) => void;
  handleScheduleToggle: (isEnabled: boolean) => void;

  onSaveAndRunClick: (onlySave: boolean) => void;
  onCancel: () => void;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

  onCollectionFileSelect: (file: File) => void;
  onEnvironmentFileSelect: (file: File) => void;

  selectedCollectionFile?: string;
  selectedEnvironmentFile?: string;
}

export default function CreateEditRunnerForm({
  name,
  description,
  secrets,
  handleOnSecretChange,
  onSaveAndRunClick,
  onCancel,
  onChangeName,
  onChangeDescription,
  onCollectionFileSelect,
  onEnvironmentFileSelect,
  scheduleEnabled,
  handleSchedulerChange,
  handleScheduleToggle,
  cron,
  selectedCollectionFile,
  selectedEnvironmentFile,
}: Props) {
  return (
    <>
      <div className="flex justify-between items-center">
        <nav className="flex my-4 text-lg" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/runners"
                  className="text-gray-400 hover:text-gray-200"
                >
                  Runners
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div className="ml-4 text-md text-gray-400" aria-current={true}>
                  {name}
                </div>
              </div>
            </li>
          </ol>
        </nav>
        <button
          onClick={() => onSaveAndRunClick(false)}
          type="button"
          className="w-28 h-9 rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
        >
          Save & Run
        </button>
      </div>
      <div className="w-full border-t border-gray-400 py-1"></div>
      <form>
        <div className="space-y-6">
          <div className="border-b border-white/10 pb-12">
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Runner name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      onChange={onChangeName}
                      value={name}
                      type="text"
                      name="runnerName"
                      id="runnerName"
                      className="flex-1 border-0 bg-transparent py-1.5 pl-3 text-white focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Description <span className="text-gray-400">(Optional)</span>
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={onChangeDescription}
                    value={description}
                    id="description"
                    name="description"
                    rows={2}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <FileUpload
                id="collectionJsonFileUpload"
                className="my-2"
                label={
                  <div>
                    Collection JSON File
                    <span className="text-gray-400"> (Required)</span>
                  </div>
                }
                onFileSelect={onCollectionFileSelect}
                selectedFile={selectedCollectionFile}
              />

              <FileUpload
                id="environmentJsonFileUpload"
                className="my-2"
                label={
                  <div>
                    Environment JSON File
                    <span className="text-gray-400"> (Optional)</span>
                  </div>
                }
                onFileSelect={onEnvironmentFileSelect}
                selectedFile={selectedEnvironmentFile}
              />
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <div className="flex items-center gap-4">
              <p className="leading-7 text-white">
                Schedule <span className="text-gray-400">(Optional)</span>
              </p>
              <Toggle
                initialEnabled={scheduleEnabled}
                onChange={handleScheduleToggle}
              />
            </div>
            <div className="">
              <CronScheduler
                onChange={handleSchedulerChange}
                showResultCron
                showResultText
                value={cron.cron}
                enabled={scheduleEnabled}
                scheduleMode={cron.scheduleMode}
                className="mt-4"
              />
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              Secrets
            </h2>

            <div className="relative flex flex-col gap-x-3">
              {secrets.map((secret) => (
                <SecretCheckbox
                  key={secret.secretId}
                  secretId={secret.secretId}
                  secretName={secret.secretName}
                  isSelected={secret.isSelected}
                  onSecretChange={(e) => {
                    console.log("secret", secret);
                    handleOnSecretChange(secret.secretId);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              Advanced Options
            </h2>

            <div className="mt-10 space-y-10">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-white">
                  By Email
                </legend>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-white"
                      >
                        Comments
                      </label>
                      <p className="text-gray-400">
                        Get notified when someones posts a comment on a posting.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="candidates"
                        name="candidates"
                        type="checkbox"
                        className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="candidates"
                        className="font-medium text-white"
                      >
                        Candidates
                      </label>
                      <p className="text-gray-400">
                        Get notified when a candidate applies for a job.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="offers"
                        className="font-medium text-white"
                      >
                        Offers
                      </label>
                      <p className="text-gray-400">
                        Get notified when a candidate accepts or rejects an
                        offer.
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-white">
                  Push Notifications
                </legend>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  These are delivered via SMS to your mobile phone.
                </p>
                <div className="mt-6 space-y-6">
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                    />
                    <label
                      htmlFor="push-everything"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Everything
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-email"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                    />
                    <label
                      htmlFor="push-email"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Same as email
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-nothing"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                    />
                    <label
                      htmlFor="push-nothing"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      No push notifications
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="my-4 flex items-center justify-end gap-x-6">
          <button
            onClick={onCancel}
            type="button"
            className="text-sm font-semibold leading-6 text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => onSaveAndRunClick(false)}
            type="button"
            className="w-28 h-9 rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
          >
            Save & Run
          </button>
          <button
            onClick={() => onSaveAndRunClick(true)}
            type="button"
            className="w-28 h-9 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}
