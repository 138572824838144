/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  postCollection,
  postEnvironment,
  postRunner,
  putRunnerByRunnerId,
  getSecret,
} from "./api-client/services.gen";
import {
  CollectionPresignedUrlPostResponse,
  GetSecretResponse,
  ListSecretsResponse,
} from "./api-client";
import { EnvironmentPresignedUrlPostResponse } from "./api-client";
import { Client } from "@hey-api/client-fetch";
import { HeaderValType } from "./components/cron-scheduler/meta";

export async function uploadFilePresignedUrl(
  preSignedUrlResponse:
    | CollectionPresignedUrlPostResponse
    | EnvironmentPresignedUrlPostResponse,
  file: File,
) {
  const { presignedUrl, fields } = preSignedUrlResponse;
  const formData = new FormData();
  formData.append("key", fields.key || "");
  formData.append("Policy", fields.Policy || "");
  formData.append("X-Amz-Signature", fields["X-Amz-Signature"] || "");
  formData.append("bucket", fields.bucket || "");
  formData.append("X-Amz-Algorithm", fields["X-Amz-Algorithm"] || "");
  formData.append("X-Amz-Credential", fields["X-Amz-Credential"] || "");
  formData.append("X-Amz-Date", fields["X-Amz-Date"] || "");
  formData.append("X-Amz-Security-Token", fields["X-Amz-Security-Token"] || "");
  formData.append("file", file, file.name);

  await fetch(presignedUrl || "", {
    method: "POST",
    body: formData,
    headers: {
      Accept: "*/*",
    },
  });
}

interface SaveAndRunInput {
  client: Client;
  runnerId?: string;
  secretIds: string[];
  collectionFile: File;
  collectionName: string;
  collectionId?: string;
  environmentFile: File;
  environmentName?: string;
  runnerName: string;
  description: string;
  cron: { cron: string[]; scheduleMode: HeaderValType };
  scheduleEnabled: boolean;
}

export async function saveAndRunClick({
  client,
  runnerId,
  collectionFile,
  collectionName,
  collectionId,
  environmentFile,
  environmentName = "",
  runnerName,
  description,
  cron,
  scheduleEnabled,
  secretIds,
}: SaveAndRunInput) {
  if (!collectionFile && !collectionName) {
    console.error("Collection file is required");
    return;
  }

  let resolvedCollectionId = collectionId;
  if (collectionFile && collectionName) {
    const collectionPreSignedUrlResponse = await postCollection({ client });
    console.log(
      "Collection pre-signed URL response",
      JSON.stringify(collectionPreSignedUrlResponse),
    );
    resolvedCollectionId =
      collectionPreSignedUrlResponse.data?.collectionId || "";
    if (collectionPreSignedUrlResponse.error) {
      console.error("Error getting presigned URL for collection");
      return;
    }
    await uploadFilePresignedUrl(
      collectionPreSignedUrlResponse.data,
      collectionFile,
    );
    if (collectionId !== resolvedCollectionId) {
      // TODO: Delete old collection file if it exists
    }
  }

  let environmentId;
  if (environmentFile) {
    const environmentPreSignedUrlResponse = await postEnvironment({ client });
    environmentId = environmentPreSignedUrlResponse.data?.environmentId;
    if (environmentPreSignedUrlResponse.error) {
      console.error("Error getting presigned URL for environment");
      return;
    }

    await uploadFilePresignedUrl(
      environmentPreSignedUrlResponse.data,
      environmentFile,
    );
  }

  if (runnerId) {
    const body = {
      name: runnerName,
      description: description,
      collectionId: resolvedCollectionId || "",
      collectionName: collectionFile ? collectionFile.name : collectionName,
      environmentId: environmentId || "",
      environmentName,
      runtimeType: "LAMBDA",
      memoryDiskMBConfig: "512-512",
      cron: cron.cron.join(" "),
      scheduleEnabled,
      scheduleMode: cron.scheduleMode,
    };
    console.log("PUT body", JSON.stringify(body));

    await putRunnerByRunnerId({
      path: {
        runnerId,
      },
      body: {
        ...body,
        secretIds,
        memoryDiskMBConfig: "512-512",
        runtimeType: "LAMBDA",
      },
    });
  } else {
    await postRunner({
      body: {
        name: runnerName,
        description: description,
        collectionId: resolvedCollectionId || "",
        collectionName: collectionFile ? collectionFile.name : collectionName,
        environmentId: environmentId || "",
        environmentName,
        runtimeType: "LAMBDA",
        memoryDiskMBConfig: "512-512",
        scheduleEnabled,
        cron: cron.cron.join(" "),
        scheduleMode: cron.scheduleMode,
        secretIds,
      },
    });
  }
}

export const getSecretFetcher = async (client: Client) => {
  // Get all secrets
  let secrets: GetSecretResponse["secrets"] = [];
  let nextToken;
  let retryCount = 0;
  const MAX_RETRY_COUNT = 50;
  do {
    retryCount++;
    // @ts-ignore This seems to be a false positive
    const response = await getSecret({ client, query: { nextToken } });
    if (response.error) {
      console.error(response.error);
      return [];
    } else {
      secrets = secrets.concat(response.data.secrets);
      nextToken = response.data.lastEvaluatedKey || "";
    }
  } while (nextToken && retryCount < MAX_RETRY_COUNT);

  return secrets;
};
