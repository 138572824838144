import React, { ChangeEvent } from "react";
import Select from "../select";
interface HourSelectProp {
  disabled?: boolean;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
  value: string;
  className?: string;
}

const buildOptions = () => {
  let options = [];
  for (let i = 0; i < 24; i++) {
    options.push(
      <option key={i} id={i.toString()}>
        {i}
      </option>,
    );
  }
  return options;
};
const options = buildOptions();

const HourSelect: React.FunctionComponent<HourSelectProp> = (props) => {
  return (
    <Select
      onChange={props.onChange}
      value={props.value}
      options={options}
      className={props.className}
      disabled={props.disabled}
    />
  );
};

export default HourSelect;
