import React, { useState } from "react";
import cronstrue from "cronstrue/i18n";
import {
  metadata,
  loadHeaders,
  HeaderKeyType,
  HeaderValType,
  HEADER_VALUES,
} from "./meta";
import "./cron-builder.css";
import { RadioGroup } from "@headlessui/react";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const scheduleModes = [
  { name: HEADER_VALUES.MINUTES, enabled: true },
  { name: HEADER_VALUES.HOURLY, enabled: true },
  { name: HEADER_VALUES.DAILY, enabled: true },
  { name: HEADER_VALUES.WEEKLY, enabled: true },
  { name: HEADER_VALUES.MONTHLY, enabled: true },
];

export const DefaultCron = "0 0 * * ?";

const defaultValue = (tab: HeaderValType): string[] => {
  let defaultValCron = metadata.find((m) => m.name == tab);

  if (!defaultValCron || !defaultValCron.initialCron) {
    console.log("DefaultCron: ", DefaultCron.split(" "));
    return DefaultCron.split(" ");
  }
  return defaultValCron.initialCron;
};

const convertToHumanCron = (cron: string[]): string => {
  const humanCron = cron.join(" ");
  console.log("humanCron: ", humanCron);
  if (!humanCron) {
    return "";
  }
  return cronstrue.toString(humanCron);
};

const defaultValues = {
  [HEADER_VALUES.MINUTES]: defaultValue(HEADER_VALUES.MINUTES),
  [HEADER_VALUES.HOURLY]: defaultValue(HEADER_VALUES.HOURLY),
  [HEADER_VALUES.DAILY]: defaultValue(HEADER_VALUES.DAILY),
  [HEADER_VALUES.WEEKLY]: defaultValue(HEADER_VALUES.WEEKLY),
  [HEADER_VALUES.MONTHLY]: defaultValue(HEADER_VALUES.MONTHLY),
};

interface CronProp {
  value: string[];
  scheduleMode: HeaderValType;
  onChange(
    scheduleMode: HeaderValType,
    newCron: string[],
    humanReadableCron: string,
  ): void;
  showResultText: boolean;
  showResultCron: boolean;
  translateFn?(key: string): string;
  locale?: string;
  options?: { headers: HeaderKeyType[] };
  className?: string;
  enabled: boolean;
}

interface State {
  values: { [key in HeaderValType]: string[] };
  scheduleMode: HeaderValType;
  headers: HeaderValType[];
  locale: string;
  humanReadableCron: string;
}

const CronScheduler: React.FunctionComponent<CronProp> = (props) => {
  const [state, setState] = useState<State>({
    values: { ...defaultValues, [props.scheduleMode]: props.value },
    headers: loadHeaders(props.options),
    locale: props.locale ? props.locale : "en",
    scheduleMode: props.scheduleMode,
    humanReadableCron: "",
  });

  const handleScheduleModeChange = (scheduleMode: HeaderValType) => {
    if (state.scheduleMode !== scheduleMode) {
      console.log("Schedule mode change", scheduleMode);
      const humanReadableCron = convertToHumanCron(state.values[scheduleMode]);
      // console.log("Schedule mode change", humanReadableCron);
      props.onChange(
        scheduleMode,
        state.values[scheduleMode],
        humanReadableCron,
      );
      setState({ ...state, scheduleMode, humanReadableCron });
    }
  };

  const onValueChange = (val: string[]) => {
    console.log("onValueChange", val);
    const humanReadableCron = convertToHumanCron(val);
    console.log("human cron", humanReadableCron);

    if (val && val.length) {
      props.onChange(state.scheduleMode, val, humanReadableCron);
      setState({
        ...state,
        values: { ...state.values, [state.scheduleMode]: val },
        humanReadableCron,
      });
    } else {
      val = DefaultCron.split(" ");
      setState({
        ...state,
        values: { ...state.values, [state.scheduleMode]: val },
        humanReadableCron,
      });
    }
  };

  const getComponent = (tab: HeaderValType) => {
    const index = state.headers.indexOf(tab);
    let selectedMetaData = metadata.find((data) => data.name === tab);
    if (!selectedMetaData) {
      selectedMetaData = metadata[index];
    }
    if (!selectedMetaData) {
      throw new Error(`Value ${tab} does not match any available headers.`);
    }
    const CronComponent = selectedMetaData.component;
    return (
      <CronComponent
        translate={translate}
        value={state.values[props.scheduleMode]}
        onChange={onValueChange}
        disabled={!props.enabled}
      />
    );
  };

  const translate = (key: string): string => {
    let translatedText = key;
    if (props.translateFn) {
      translatedText = props.translateFn(key);
      if (typeof translatedText !== "string") {
        throw new Error("translateFn expects a string translation");
      }
    }
    return translatedText;
  };

  const cron = state.values[props.scheduleMode].join(" ");
  const humanReadableCron = cron ? cronstrue.toString(cron) : "";

  const activeScheduleModes = scheduleModes.map((sm) => {
    return { name: sm.name, enabled: props.enabled };
  });
  const scheduleEnableCss = !props.enabled
    ? "cursor-not-allowed opacity-25 pointer-events-none"
    : "";

  return (
    <div className={`${props.className || ""}`}>
      <RadioGroup
        value={props.scheduleMode}
        // @ts-ignore
        onChange={handleScheduleModeChange}
        className="mt-2"
        disabled={!props.enabled}
        //   defaultValue={props.scheduleMode}
      >
        <RadioGroup.Label className="sr-only">
          Select a scheduling mode
        </RadioGroup.Label>
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
          {activeScheduleModes.map((option) => (
            <RadioGroup.Option
              key={option.name}
              value={option.name}
              className={({ active, checked }) =>
                classNames(
                  option.enabled
                    ? "cursor-pointer focus:outline-none"
                    : "cursor-not-allowed opacity-25",
                  active ? "ring-2 ring-gray-500 ring-offset-2" : "",
                  checked
                    ? "bg-gray-700 text-white hover:bg-gray-500 ring-2 ring-inset ring-gray-300"
                    : "bg-gray-700 text-white hover:bg-gray-600",
                  "flex items-center justify-center rounded-md py-2 px-1 text-sm font-semibold uppercase sm:flex-1",
                )
              }
              disabled={!option.enabled}
            >
              <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className={`p-6 h-50 ${scheduleEnableCss}`}>
        {props.scheduleMode
          ? getComponent(props.scheduleMode)
          : "Select a header"}
      </div>
      {props.enabled && (
        <div>
          {props.showResultCron && (
            <div className="text-gray-400 px-6">{humanReadableCron}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default CronScheduler;
