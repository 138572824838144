export async function sleep(waitMS: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, waitMS);
  });
}

export function makeTimeDiffPretty(t1ms: number, t2ms: number) {
  const diffSeconds = Math.round((t2ms - t1ms) / 1000);
  if (diffSeconds < 60) {
    if (diffSeconds < 2) {
      return "1 second";
    }
    return `${diffSeconds} seconds`;
  }
  if (diffSeconds < 3600) {
    if (diffSeconds < 120) {
      return "1 minute";
    }
    return `${Math.floor(diffSeconds / 60)} minutes`;
  }
  if (diffSeconds < 86400) {
    if (diffSeconds < 7200) {
      return "1 hour";
    }
    return `${Math.floor(diffSeconds / 3600)} hours`;
  }
  if (diffSeconds < 604800) {
    if (diffSeconds < 172800) {
      return "1 day";
    }
    return `${Math.floor(diffSeconds / 86400)} days`;
  }
  if (diffSeconds < 2419200) {
    if (diffSeconds < 1209600) {
      return "1 week";
    }
    return `${Math.floor(diffSeconds / 604800)} weeks`;
  }
  if (diffSeconds < 29030400) {
    if (diffSeconds < 4838400) {
      return "1 month";
    }
    return `${Math.floor(diffSeconds / 2419200)} months`;
  }
  return null;
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

// https://park.is/blog_posts/20240803_extracting_timestamp_from_uuid_v7/
export function extractTimestampFromUUIDv7(uuid: string): Date {
  // split the UUID into its components
  const parts = uuid.split("-");

  // the second part of the UUID contains the high bits of the timestamp (48 bits in total)
  const highBitsHex = parts[0] + parts[1].slice(0, 4);

  // convert the high bits from hex to decimal
  // the UUID v7 timestamp is the number of milliseconds since Unix epoch (January 1, 1970)
  const timestampInMilliseconds = parseInt(highBitsHex, 16);

  // convert the timestamp to a Date object
  const date = new Date(timestampInMilliseconds);

  return date;
}
