import { FunctionComponent, useEffect, useState } from "react";
import Select from "../select";
import React from "react";

interface HourlyCronProp {
  onChange(e?: string[]): void;
  value: string[];
  translate(e: string): string;
  disabled?: boolean;
}

const HourlyCron: FunctionComponent<HourlyCronProp> = (props) => {
  const onHourChange = (e: { target: { value: string } }) => {
    const parsedValue = parseInt(e.target.value);
    if ((parsedValue > 0 && parsedValue < 24) || e.target.value === "") {
      const val = [
        props.value[0],
        "*/" + e.target.value,
        props.value[2],
        props.value[3],
        props.value[4],
      ];
      props.onChange(val);
    }
  };
  const onMinuteChange = (e: { target: { value: string } }) => {
    const parsedValue = parseInt(e.target.value);
    if ((parsedValue >= 0 && parsedValue < 60) || e.target.value === "") {
      const val = [
        e.target.value,
        props.value[1],
        props.value[2],
        props.value[3],
        props.value[4],
      ];
      props.onChange(val);
    }
  };

  const translateFn = props.translate;
  return (
    <div className="">
      <div className="">
        <div className="">
          <span className="text-white">{translateFn("Every")} </span>
          <Select
            onChange={onHourChange}
            value={props.value[1].split("/")[1]}
            disabled={props.disabled}
            options={[
              <option key="1" value="1">
                1
              </option>,
              <option key="2" value="2">
                2
              </option>,
              <option key="3" value="3">
                3
              </option>,
              <option key="4" value="4">
                4
              </option>,
              <option key="5" value="6">
                6
              </option>,
              <option key="6" value="8">
                8
              </option>,
              <option key="7" value="12">
                12
              </option>,
              <option key="8" value="24">
                24
              </option>,
            ]}
            className="mr-2"
          />
          <span className="text-white mr-2">{translateFn("hour(s),")}</span>
          <Select
            onChange={onMinuteChange}
            value={props.value[0]}
            disabled={props.disabled}
            options={[
              <option key="1" value="0">
                0
              </option>,
              <option key="2" value="5">
                5
              </option>,
              <option key="3" value="10">
                10
              </option>,
              <option key="4" value="30">
                30
              </option>,
              <option key="5" value="45">
                45
              </option>,
            ]}
            className="mr-2"
          />
          <span className="text-white">
            {translateFn("minute(s) past the hour")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HourlyCron;
