/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { postSecret, putSecretBySecretId } from "./api-client/services.gen";
import { Client } from "@hey-api/client-fetch";

interface SaveAndRunInput {
  client: Client;
  secretId?: string;
  secretName: string;
  secretValue?: string;
  description: string;
}

export async function saveAndRunClick({
  client,
  secretId,
  secretName,
  secretValue,
  description,
}: SaveAndRunInput) {
  if (secretId) {
    const body = {
      secretName,
      secretValue,
      description,
    };
    console.log("Secret body", JSON.stringify(body));
    await putSecretBySecretId({
      client,
      path: { secretId },
      body,
    });
  } else {
    const body = {
      secretName,
      // Secret value should defined regardless
      secretValue: secretValue || "",
      description,
    };
    console.log("Secret body", JSON.stringify(body));
    await postSecret({
      client,
      body,
    });
  }
}
