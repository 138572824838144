import React, { ChangeEvent, FunctionComponent, ReactElement } from "react";
import Select from "../select";

interface MinutesSelectProp {
  disabled?: boolean;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
  value: string;
  className?: string;
}

const buildOptions = (): ReactElement[] => {
  let options = [];
  for (let i = 0; i < 60; i++) {
    options.push(
      <option key={i} id={i.toString()}>
        {i}
      </option>,
    );
  }
  return options;
};

const options = buildOptions();

const MinutesSelect: FunctionComponent<MinutesSelectProp> = (props) => {
  return (
    <Select
      onChange={props.onChange}
      value={props.value}
      options={options}
      className={props.className}
      disabled={props.disabled}
    />
  );
};
export default MinutesSelect;
