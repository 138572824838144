import React, { ChangeEventHandler, FunctionComponent } from "react";
import Select from "../select";

export interface MinutesCronProp {
  onChange(e: string[]): void;
  value: string[];
  translate(e: string): string;
  disabled?: boolean;
}

const MinutesCron: FunctionComponent<MinutesCronProp> = (props) => {
  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const value = parseInt(e?.target?.value);
    if ((value > 0 && value <= 60) || e.target.value === "") {
      const val = [
        "*/" + e.target.value,
        props.value[1],
        props.value[2],
        props.value[3],
        props.value[4],
      ];
      props.onChange(val);
    }
  };

  const value = props.value[1].split("/")[1];
  return (
    <div className="well text-white">
      {props.translate("Every")}{" "}
      <Select
        onChange={onChange}
        value={value}
        disabled={props.disabled}
        options={[
          <option key="1" value="1">
            1
          </option>,
          <option key="2" value="2">
            2
          </option>,
          <option key="3" value="3">
            3
          </option>,
          <option key="4" value="4">
            4
          </option>,
          <option key="5" value="5">
            5
          </option>,
          <option key="6" value="10">
            10
          </option>,
          <option key="7" value="15">
            15
          </option>,
          <option key="8" value="20">
            20
          </option>,
          <option key="9" value="30">
            30
          </option>,
          <option key="10" value="45">
            45
          </option>,
          <option key="11" value="60">
            60
          </option>,
        ]}
      />{" "}
      {props.translate("minute(s)")}
    </div>
  );
};
export default MinutesCron;
