import { CloudArrowUpIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon, XCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import {
  ChangeEvent,
  DragEventHandler,
  MouseEventHandler,
  ReactNode,
  useState,
} from "react";

interface SelectedFileInput {
  filename: string;
  id: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

function SelectedFile({ filename, id, onClick }: SelectedFileInput) {
  return (
    <div
      className="cursor-pointer group flex flex-col justify-center items-center"
      onClick={onClick}
    >
      <div className="relative w-12 h-12">
        <div className="relative">
          <div className="absolute h-6 w-6 bg-gray-800 right-0 top-0 rounded-full" />
          <XCircleIcon className="text-red-500 group-hover:text-red-300 absolute h-6 w-6 right-0 top-0" />
        </div>
        <DocumentTextIcon
          className="h-12 w-12 text-gray-500 group-hover:text-gray-200"
          aria-hidden="true"
        />
      </div>
      <p className="text-gray-400 group-hover:text-gray-200 truncate max-w-md">
        {filename}
      </p>
    </div>
  );
}

interface FileUploadInput {
  className?: string;
  label?: string | ReactNode;
  id: string;
  selectedFile?: string;
  onFileSelect: (file: File) => void;
}

export default function FileUpload({
  className,
  label,
  id,
  selectedFile,
  onFileSelect: onFileSelect,
}: FileUploadInput) {
  const [dragOverActive, setDragOverActive] = useState(false);
  const [eventCount, setEventCount] = useState(0);
  const [selectedFileInternal, setSelectedFile] = useState(selectedFile);

  const handleCollectionOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      console.log("Files: " + e.target.files[0].name);
      setSelectedFile(e.target.files[0].name);
      onFileSelect(e.target.files[0]);
    }
  };

  const handleOnDrop: DragEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOverActive(false);

    if (selectedFileInternal) {
      // delete previous file via api call
    }

    // api call to save file and return file id

    setSelectedFile(e.dataTransfer.files[0].name);

    // onFileSelect(e.dataTransfer.files[0]);
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEventCount((ec) => ec + 1);
    setDragOverActive(true);
  };

  const handleDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEventCount((ec) => ec - 1);

    if (eventCount <= 1) {
      setDragOverActive(false);
    } else {
      setDragOverActive(true);
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFileDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    // Also send api call to delete file
    setSelectedFile(undefined);
  };

  const ptrEventsClassName = dragOverActive
    ? "pointer-events-none"
    : "pointer-events-auto";
  const bgColorClassName = dragOverActive ? "bg-gray-200" : "bg-gray-800";

  return (
    <div className={`col-span-full ${className}`}>
      <div className="block text-sm font-medium leading-6 text-white">
        {label}
      </div>
      <div
        className="mt-2 relative"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleOnDrop}
      >
        <span
          className={`${bgColorClassName} ${ptrEventsClassName} h-9 w-28 absolute inset-3 flex text-sm leading-6 text-gray-400`}
        >
          <label
            htmlFor={`file-upload-${id}`}
            className="h-9 w-20 cursor-pointer rounded-md bg-gray-900 font-semibold text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-200"
          >
            <div className="flex items-center justify-center w-20 h-9 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
              Browse
            </div>
            <input
              id={`file-upload-${id}`}
              name={`file-upload-${id}`}
              type="file"
              className="hidden"
              placeholder="Upload a collection.json file"
              accept="application/json"
              onChange={handleCollectionOnChange}
            />
          </label>
        </span>
        <div
          className={`${bgColorClassName} h-90 w-full mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10`}
        >
          {!selectedFileInternal ? (
            <div
              className={`${bgColorClassName} ${ptrEventsClassName} text-center`}
            >
              <span className="text-gray-500">or drag and drop</span>
              <CloudArrowUpIcon
                className="mx-auto h-12 w-12 text-gray-500"
                aria-hidden="true"
              />
            </div>
          ) : (
            <SelectedFile
              onClick={handleFileDelete}
              filename={selectedFileInternal || ""}
              id={id}
            />
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}
